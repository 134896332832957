.news-home {
  margin: auto;
  max-width: 600px;
  width: 90%;
  background-color: #f1eee9;
  padding: 16px 16px 0px 16px;
  position: relative;
}

.news-home .text,
.news-home .text-2 {
  font-size: 16px;
  color: #252525;
}

.news-home .text-2 b {
  color: #252525 !important;
  cursor: pointer;
  text-decoration: none;
}

.news-home .text-2 {
  text-align: left;
}

.news-home .text-3 {
  font-size: 16px;
  color: #00502F;
  text-align: left;
}

.news-home .btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.news-home .content {
  text-align: center;
  padding: 8px 8px;
}

.news-home .content .icon {
  color: #00502F;
  font-size: 50px;
}

.news-home .content .calendar-text {
  line-height: 50px;
}

.news-home .content .calendar-text h5 {
  line-height: 50px;
  font-size: 22px;
  border: 2px solid #00502F;
  color: #00502F;
  cursor: pointer;
}

.news-home .content .calendar-text h5:hover,
.news-home .content .calendar-text h5:active {
  background-color: #00502F20;
}

.news-home .content .padx-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.news-home .content .padr-0 {
  padding-right: 0px !important;
}
