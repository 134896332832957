.header {
  width: 100%;
  color: #fff;
  background-color: #00502F;
  padding: 16px 24px;
  font-size: 20px;
  margin-bottom: 16px;
}

.header .icon {
  float: left;
  font-size: 32px;
  cursor: pointer;
}

.header .text {
  margin: auto;
  max-width: 600px;
  text-align: center;
}

.header .text span {
  cursor: pointer;
}
