.react-calendar {
  margin: auto;
  max-width: 90% !important;
}

.container-calendar-list {
  margin: auto;
  max-width: 600px;
}

.calendars-list {
  margin: auto;
  max-width: 90% !important;
  background-color: #ebebeb;
  border-radius: 10px;
}

.calendars-list .calendar-title {
  color: #00502F;
}

.blink .MuiPickersDay-daySelected {
  /* background-color: #00502F !important; */
  -webkit-animation: blink 1s infinite;
  -moz-animation:    blink 1s infinite;
  -o-animation:      blink 1s infinite;
  animation:         blink 1s infinite;
}

@keyframes blink { 
  0%{ 
    color: #FFF;
    background-color: #00502F;
  }
  100%{
    color: #000;
    background-color: #FFF;
  }
}