.contact-highlight {
  border-radius: 10px;
  -webkit-animation: blink 1s infinite;
  -moz-animation:    blink 1s infinite;
  -o-animation:      blink 1s infinite;
  animation:         blink 1s infinite;
}

.MuiFormLabel-root.Mui-focused {
  color: #00502f !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #00502f !important;
}

.MuiList-root {
  max-height: 300px;
}

@keyframes blink { 
  0%{ 
    color: #000;
    background-color: #FFF;
  }
  100%{
    color: #FFF;
    background-color: #00502F;
  }
}
