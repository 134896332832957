.user-profile-img {
  width: 100%;
  height: 100%;
  z-index: 20;
  border-radius: 50%;
  vertical-align: initial;
}

.user-profile-img:hover {
  background-color: aqua;
}

.react-tel-input .form-control {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 0;
  border-width: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.react-tel-input .form-control:after {
  border-bottom: 2px solid #3f51b5;
} 

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: 0;
}