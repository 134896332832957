.bot-nav {
  box-shadow: 0px -3px 5px grey;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  text-align: center;
  margin: auto;
  padding: 2px;
}

.bot-nav .bot-row {
  bottom: 0;
  max-width: 600px;
  text-align: center;
  margin: auto;
  font-size: 11px;
}

.bot-nav .bot-row .img-nav {
  max-width: 50px;
  width: 100%;
}

.bot-nav .bot-row .cursor-pointer {
  cursor: pointer;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.bot-nav .bot-row .active {
  position: absolute;
  box-shadow: 0px -5px 5px #00502F;
  background-color: #00502F;
  max-width: 200px;
  border-radius: 4px;
  height: 4px;
  top: -3px;
  left: 25px;
  right: 25px;
}
