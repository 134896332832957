.menu .menu-row {
  max-width: 600px;
  width: 90%;
  text-align: center;
  margin: auto;
  font-size: 10px;
}

.menu .menu-row .cursor-pointer {
  cursor: pointer;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
